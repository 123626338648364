<template>
    <card class="counter-container">
        <div class="d-flex justify-content-between">
			<div class="">
				<div :class="['round-icon', backgroundIconClass]">
					<i class="fa-regular fa-eye"></i>
				</div>
			</div>
			<div class="w-100 pl-3">
					<div class="counter-row pb-1">
						<div>
							Allowed Words
						</div>
						<div class="counter">
							<span :class="counterColorClass">{{ counter.words }}</span> / <span class="fs-12 font-weight-400"> {{ maxContentLength }}</span>
						</div>
					</div>
					<div class="counter-row py-1">
						<div>
							Paragraphs
						</div>
						<div class="counter">
							{{ counter.paragraphs }}
						</div>
					</div>
					<div class="counter-row pt-1">
						<div>
							Headings
						</div>
						<div class="counter">
							{{ counter.headings }}
						</div>
					</div>
			</div>
		</div>
    </card>
</template>

<script>

export default {
	name: "TextCounter",
	components: {
    },
	props: {
		content: {
			type: String
		},
		maxContentLength: {
			type: Number
		}
	},
	data() {
		return {
			counter: {
				words: 0,
				paragraphs: 0,
				headings: 0
			}
			
		}
	},

	computed: {
		backgroundIconClass() {
			return this.counter.words > this.maxContentLength ? 'higher-content' : (this.counter.words == 0 ? 'empty-content' : 'lower-content');
		},

		counterColorClass() {
			return this.counter.words > this.maxContentLength ? 'text-orange' : (this.counter.words == 0 ? '' : 'text-green');
		}
	},

	watch: {
		content() {
			this.counter.words = this.content != '' && this.content != null ? this.wordsCounter(this.content) : 0;
			this.counter.paragraphs = this.content != '' && this.content != null ? this.paragraphsCounter(this.content) : 0
			this.counter.headings = this.content != '' && this.content != null ? this.headingsCounter(this.content) : 0
		}
	},

	methods: {
		wordsCounter(text) {
			let strippedContent =text.replace(/<[^>]*>?/gm, '')
			let words = strippedContent.trim().split(/\s+/).length;
			return words;
		},

		paragraphsCounter(text) {
			var parsed = new DOMParser().parseFromString(text.trim(), 'text/html');
			const paragraphs = parsed.querySelectorAll('p');
			const notEmptyParagraphs = Array.from(paragraphs).filter(p => p.innerText != '');
			return notEmptyParagraphs.length;
		},
		
		headingsCounter(text) {
			var parsed = new DOMParser().parseFromString(text.trim(), 'text/html');
			const headings = parsed.querySelectorAll('h1, h2, h3, h4, h5, h6');
			const notEmptyHeadings = Array.from(headings).filter(h => h.innerText != '');
			return notEmptyHeadings.length;
		},
	},

	mounted() {
		this.counter.words = this.content != '' && this.content != null ? this.wordsCounter(this.content) : 0;
		this.counter.paragraphs = this.content != '' && this.content != null ? this.paragraphsCounter(this.content) : 0
		this.counter.headings = this.content != '' && this.content != null ? this.headingsCounter(this.content) : 0
	}
};
</script>

<style lang="scss">
.counter-container {
	margin-bottom: 1.5rem;

	.card-body {
		padding: 0.75rem 1rem !important;

		.round-icon {
			height: 30px;
			width: 30px;
			border-radius: 30px;
			color: white;
			text-align: center;
			line-height: 30px;
			font-size: 16px;

			&.empty-content {
				background: #004583;
			}

			&.lower-content {
				background: #00BC7E;
			}

			&.higher-content {
				background: #FF9D00;
			}
		}

		.counter-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #999999;
    		font-size: 12px;
			font-weight: 400;
			padding-right: .5rem;

			&:not(:last-child) {
				border-bottom: 1px solid #F2F4F6;
			}

			.counter {
				font-size: 16px;
				font-weight: 700;

				.text-green {
					color: #00BC7E;
				}
				.text-orange {
					color: #FF9D00;
				}
			}
		}
	}
}

</style>