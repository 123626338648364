<template>
	<div  class="orderResult orderResult__video" style="background: transparent;">
		<div class="card rounded overflow-hidden d-flex justify-content-center align-items-center"
			style="width: 200px;height:200px">
			<video @click="dialogVideoPreviewVisible = true" class="video-slot" :src="order?.content" :preview-src-list="[order?.content]"></video>
		</div>

		<el-dialog :visible.sync="dialogVideoPreviewVisible">
			<video controls class="video-slot">
				<source :src="order?.content" :preview-src-list="[order?.content]">
			</video>
        </el-dialog>

	</div>
</template>

<script>
import { Dialog } from 'element-ui';
export default {
	components: {
		[Dialog.name]: Dialog,
	},
	data() {
		return {
			dialogVideoPreviewVisible: false,
		}
	},
	computed: {
		order() {
			return this.$store.getters.getOrder;
		}
	},
	mounted: {
		openPreview() {

		}
	}
};
</script>