<template>
	<div class="orderResult">
		<div class="card bg-secondary rounded overflow-hidden d-flex justify-content-center align-items-center"
			style="width: 200px;">
			<el-image :src="order?.content" :preview-src-list="[order?.content]" fit="cover">
				<div slot="error" class="image-slot"></div>
				<div slot="placeholder" class="image-slot">
					Loading<span class="dot">...</span>
				</div>
			</el-image>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		order() {
			return this.$store.getters.getOrder;
		}
	}
};
</script>