import { Message } from "element-ui";

export { delay, pollUntilCondition, copyToClipboard };

const delay = (interval) => {
  return new Promise((resolve) => setTimeout(resolve, interval));
};

const pollUntilCondition = async (
  pollingFunction,
  conditionFunction,
  interval
) => {
  while (true) {
    console.log("pollUntilCondition");
    const result = await pollingFunction();
    console.log("result", result);
    if (conditionFunction(result)) {
      return result;
    }
    await delay(interval);
  }
};

const copyToClipboard = async (string,type) => {
  console.log(string);
  try {
    if (type == 'copyText') {
      await navigator.clipboard.writeText(string);
    } else {
      var type = "text/html";
      var blob = new Blob([string], { type });
      var data = [new ClipboardItem({ [type]: blob })];
      await navigator.clipboard.write(data)
    }
    

    Message({
      showClose: true,
      message: 'Text copied to clipboard',
      type: 'success',
    });

  } catch (error) {
    Message({
      showClose: true,
      message: 'Failed to copy text',
      type: 'error',
    });
  }
};