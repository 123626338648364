<template>
    <div>
        <ElTable  :data="this.orderFiltered" :key="tableKey">
            <ElTableColumn label="#" sortable>
                <template #default="scope">
                    {{ scope.row.key }}
                </template>
            </ElTableColumn>

            <ElTableColumn label="Keywords" sortable>
                <template #default="scope">
                    {{ scope.row.keyword }}
                </template>
            </ElTableColumn>

            <ElTableColumn align="right" label="Count" sortable>
                <template #default="scope">
                    {{ scope.row.count }}
                </template>
            </ElTableColumn>

            <ElTableColumn align="right" label="Percent" sortable>
                <template #default="scope">
                    {{ scope.row.percent }}
                </template>
            </ElTableColumn>

        </ElTable>
        <!-- Table pagination -->
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
            <p class="card-category">
                {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
            </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="currentPage" :per-page="pagination.perPage"
            :total="total">
            </base-pagination>
        </div>

    </div>

</template>

<script>
import { Table, TableColumn, Select, Option, DatePicker, Checkbox } from 'element-ui';
import { BasePagination } from 'src/components';

export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        [Checkbox.name]: Checkbox,
        BasePagination
    },
  name: 'seo-page-autority',
  data() {
    return {
        toolSlug: this.$route.params.slug,
        tableData: [],
        tableKey: 0,
        pagination: {
            perPage: 10,
            perPageOptions: [5, 10, 25, 50],
        },
        currentPage: 1,
        dataToMove: 0,
        orderFiltered: [],
    }
  },
  computed: {
    order() {
      return JSON.parse(this.$store.getters.getOrder.content)
    },
    to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
    },
    from() {
        return this.pagination.perPage * (this.currentPage - 1);
    },
    total() {
        return this.order?.length
    },

    fetchFilteredData() {
        for (let i = 0; i < this.order.length; i++) {
            this.orderFiltered.push(this.order[this.dataToMove]) 
            this.dataToMove = this.dataToMove + 1
            console.log(this.dataToMove,'ao');
            if (this.dataToMove >= this.to) {
                console.log('hey');
                return
            }
        }
    }	
  },
  watch: {
		currentPage() {
			console.log('ao');
			this.dataToMove = this.from
			this.orderFiltered = []
		}

	},
  mounted() {  }
};

</script>