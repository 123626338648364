import { createTextOrder, createMediaOrder, updateOrderApi } from "../api/orderApi";
import store from '@/store/store';

export { createOrder, getStatusLabel, getStatusSlug, updateOrder };

const createOrder = async (bodyParams, type = "text") => {
  const user = store.getters.getUser;
  bodyParams.user_id = user.id;
  bodyParams.workspace_id = store.getters.selectedWorkspace?.id ?? '';

  let response;

  switch (type) {
    case "media":
      response = await createMediaOrder(bodyParams);
      break;
    case "text":
    default:
      response = await createTextOrder(bodyParams);
  }

  console.log('createOrder response', response);

  return response;
}

const updateOrder = async (id, content, title = null) => {
  const user = store.getters.getUser;

  if (user) {
    const bodyParams = {
      user_id: user.id,
      order_detail_id: id,
      content: content,
      title: title
    };

    let response;
    response = await updateOrderApi(bodyParams);

    return response;
  }

  throw Error;
};

/**
 * Maps backend status to frontend status label.
 *
 * @param {string} status - The status from the backend to be mapped.
 * @returns {string} - The corresponding frontend status label.
 */
const getStatusLabel = (status) => {
  switch (status) {
    case "failed":
      return "Failed";
    case "accepted":
      return "Accepted";
    case "delivered":
      return "Delivered";
    case "rejected":
      return "Rejected";
    case "refunded":
      return "Refunded"
    case "cancelled":
      return "Cancelled"
    default:
      return "In Progress";
  }
};

/**
 * Maps frontend status label to backand.
 *
 * @param {string} status - The status from the frontend label to be mapped.
 * @returns {array} - The corresponding backend status.
 */
const getStatusSlug = (status) => {
  switch (status) {
    case "failed":
      return ["failed"];
    case "accepted":
      return ["accepted", "delivered"];
    case "in_progress":
      return ["pending", "waiting_queue", "processing"];
    case "all":
    default:
      return [];
  }
};
