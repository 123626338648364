<template>
<card id="taskAssignmentCard">
	<div class="row">
		<div class="col-12">
			<div class="fs-12 text-muted">
				<!-- Priority: <span class="mr-2 font-weight-700 text-capitalize">{{ task?.priority }}</span> 
				Due Date: <span class="mr-2 font-weight-700">{{ formatDate(task?.deadline) }}</span> <br> -->
				Created on <span class="font-weight-700">{{ formatDate(task?.created_at) }}</span>
			</div>
			<h4 class="card-title mt-0">{{ task?.title }}</h4>

			<div v-if="task.status == 'ready'" class="badge yellow-badge fs-12 font-weight-700 mb-4">Sent for approval on {{ formatDate(task?.status_updated_at) }}</div>
			
			<div v-if="task.status == 'rejected'" class="disabled-input fs-12 mb-4">
				<p class="font-weight-700 text-danger fs-12">Rejected on {{ formatDate(task?.status_updated_at) }}</p>
				<p class="font-weight-700">Comment</p>
				<p>{{ task?.rejected_note }}</p>
			</div>
			
			<div v-if="task.status == 'approved'" class="badge green-badge fs-12 font-weight-700 mb-4">Approved on {{ formatDate(task?.status_updated_at) }}</div>
			
			<div v-if="task.status == 'completed'" class="badge green-badge fs-12 font-weight-700 mb-4">Published on {{ formatDate(task?.status_updated_at) }}</div>
			
			
		</div>

		<!-- priority -->
		<div class="col-6 pr-2">
			<div class="form-group has-label">
				<label>{{ $t('taskAssignment.priority.label') }}</label>
				<el-select v-model="form.priority" :disabled="user.role == 'user'"
					class="select-primary text-capitalize" size="medium"
					@change="updateTask()"
					>
					<el-option class="select-primary text-capitalize" value="low" :label="$t('taskAssignment.priority.options.low')" />
					<el-option class="select-primary text-capitalize" value="medium" :label="$t('taskAssignment.priority.options.medium')" />
					<el-option class="select-primary text-capitalize" value="high" :label="$t('taskAssignment.priority.options.high')" />
				</el-select>
			</div>
		</div>

		<!-- due date -->
		<div class="col-6 pl-2">
			<div class="form-group has-label">
				<label>{{ $t('taskAssignment.deadline.label') }}</label>
				<el-date-picker
					v-model="form.deadline"
					:disabled="user.role == 'user'"
					type="date"
					placeholder="dd/mm/yyyy"
					format="dd/MM/yyyy"
					value-format="yyyy-MM-dd"
					:clearable="false"
					@change="updateTask()"
				>
				</el-date-picker>
			</div>
		</div>

		<!-- assignee -->
		<div class="col-12">
			<div class="form-group has-label">
				<label>{{ $t('taskAssignment.assignee.label') }}</label>
				<el-select v-model="form.assignee_id" v-loading="loadingUsers" clearable :disabled="!['waiting', 'on_going'].includes(task.status) && user.role == 'user'"
					class="select-primary" size="medium" :placeholder="$t('taskAssignment.assignee.placeholder')"
					@change="form.assignee_id ? updateTask('on_going') : updateTask('waiting', null)"
					>
					<el-option v-for="u in users" :key="u.user.id" class="select-primary" :value="u.user.id" :label="u.user.name.trim() != '' ? u.user.name : u.user.email" />
				</el-select>
				<div v-if="['waiting', 'on_going'].includes(task.status) && user.role == 'user'" 
					role="button" class="fs-12 mt-2 position-absolute right-0 text-default text-underline mb-3"
					@click="updateTask('on_going', user.id)"
					>
					Assign to me
				</div>
			</div>
		</div>

		<!-- status-->
		<div class="col-12">
			<div class="form-group has-label mb-3">
				<label>Status</label>
				<div v-loading.target="updating" class="fs-12  disabled-input text-capitalize">{{ $t(`taskAssignment.status.options.${task.status}`) }}</div>
				<!-- <el-select v-model="form.status" v-loading="updating" :disabled="!['waiting', 'on_going'].includes(task.status) && user.role == 'user'"
					class="select-primary" size="medium" placeholder="status"
					@change="updateTask(form.status)">
					<el-option v-for="s in statuses" class="select-primary" :key="s.value" :value="s.value" :label="s.label" :disabled="s.disabled" />
				</el-select> -->
			</div>
		</div>

		<div v-if="((['on_going', 'rejected'].includes(task.status) && form.assignee_id == user.id && order?.status === 'accepted') || (['waiting', 'on_going', 'rejected'].includes(task.status) && user.role != 'user')) && !updating" class="col-12 mb-3">
			<base-button class="btn__generate w-100" type="primary" @click="confirmTask()">
				Confirm edits and submit
			</base-button>
		</div>

		<div v-if="task.status == 'ready' && user.role != 'user'" class="col-12 mb-3 d-flex">
			<base-button class="btn_add_link w-100 mr-1" type="primary" @click="rejectFormVisible = true">
				Reject
			</base-button>
			<base-button class="btn__generate w-100 ml-1" type="primary" @click="approveTask()">
				Approve
			</base-button>
		</div>

		<div v-if="task.status == 'approved'" class="col-12 mb-3">
			<base-button class="btn__generate w-100" type="primary" @click="publishTask()">
				Mark as completed
			</base-button>
		</div> 

		<div class="col-12 mt-1">
			<el-collapse v-model="orderCollapse">
				<el-collapse-item :title="orderCollapseTitle" name="order">	
					<div class="row">	
						<div class="col-6 pr-2 mb-3">
							<label>Type</label>
							<div class="fs-12 disabled-input text-uppercase">{{ $t(`taskAssignment.type.options.${task?.product_type}`) }}</div>
						</div>

						<div class="col-6 pl-2 mb-3">
							<label>Workflow</label>
							<div class="fs-12  disabled-input text-capitalize">{{ task?.action }}</div>
						</div>

						<div class="col-12 mb-3">
							<label>{{ $t('taskAssignment.task_description.label') }}</label>
							<div class="fs-12  disabled-input">{{ task?.description }}</div>
						</div>

						<div class="col-12 mb-3">
							<label>{{ $t('taskAssignment.additional_content.label') }}</label>
							<div class="fs-12 disabled-input">{{ api_input?.additional_content ?? '' }}</div>
						</div>

						<div class="col-12 mb-3">
							<label>{{ $t('tovBrandVoice.bv') }}</label>
							<div class="fs-12 disabled-input text-capitalize">{{ api_input?.brandVoiceName }}</div>
						</div>

						<!-- <div v-if="api_input?.guidelines" class="col-12 mb-3">
							<div class="disabled-input border-0 mt-1">
								<label>{{ $t('tovBrandVoice.bv') }}</label>
								<div class="fs-12 disabled-input text-capitalize">{{ api_input?.brandVoiceName }}</div>
								<el-collapse v-model="brandVoiceCollapse">
									<el-collapse-item :title="brandVoiceCollapseTitle" name="brand-voice" class="further-info">	
										<div class="row pt-3">	
											<div class="col-12 mb-2">
												<label>Guidelines</label>
												<div class="fs-12 text-muted">{{ api_input?.guidelines }}</div>
											</div>
										</div>
									</el-collapse-item>
								</el-collapse>
							</div>
						</div>
						<div v-else class="col-12 mb-3">
							<label>{{ $t('tovBrandVoice.bv') }}</label>
							<div class="fs-12 disabled-input text-capitalize">Neutral</div>
						</div> -->

						<div class="col-12 mb-3">
							<label>{{ $t('linkedKeywords.label') }}</label>
							<div class="d-flex flex-wrap">
								<a v-for="keyword in api_input?.linked_keywords" :key="keyword.id" :href="keyword.url" target="_blank" class="fs-12 badge blue-badge">{{ keyword.keyword }}</a>
							</div>
						</div>

						<div class="col-12 mb-3">
							<label>{{ $t('CategoryCards.soft_keywords.label') }}</label>
							<div v-if="api_input?.soft_keywords && api_input?.soft_keywords.length > 0" class="d-flex flex-wrap">
								<div v-for="keyword in api_input?.soft_keywords" :key="keyword" class="fs-12 badge blue-badge">{{ keyword }}</div>
							</div>
							<div v-else>
								---
							</div>
						</div>

						<div class="col-12 mb-3">
							<label>{{ $t('CategoryCards.headings.label') }}</label>
							<div v-if="api_input?.headings && api_input?.headings.length > 0" class="d-flex flex-wrap">
								<div v-for="h2 in api_input?.headings" :key="h2" class="fs-12 badge blue-badge">{{ h2 }}</div>
							</div>
							<div v-else>
								---
							</div>
						</div>


						<div class="col-12 mb-3">
							<div class="disabled-input border-0 mt-1">
								<label>{{ $t('CategoryCards.page_url.label') }}</label>
								<div class="fs-12 disabled-input">
									<a :href="api_input?.page_url[0].url" target="_blank">{{ api_input?.page_url[0].url }}</a>
								</div>
								<el-collapse v-model="pageUrlCollapse">
									<el-collapse-item :title="pageUrlCollapseTitle" name="page-url" class="further-info">
										<div class="row pt-3">	
											<div class="col-12 mb-2">
												<label>Title</label>
												<div class="fs-12 text-muted">{{ api_input?.page_url[0].page_title }}</div>
											</div>
											<div class="col-12 mb-2">
												<label>Description</label>
												<div class="fs-12 text-muted">{{ api_input?.page_url[0].page_description }}</div>
											</div>
											<div class="col-12 mb-2">
												<label>Name</label>
												<div class="fs-12 text-muted">{{ api_input?.page_url[0].page_name }}</div>
											</div>
											<div class="col-12 mb-2">
												<label>URL</label>
												<a :href="api_input?.page_url[0].url" target="_blank" class="fs-12 d-block text-muted">{{ api_input?.page_url[0].url }}</a>
											</div>
											<div class="col-12 mb-2">
												<label>Type</label>
												<div class="fs-12 text-muted">{{ api_input?.page_url[0].page_type }}</div>
											</div>
											<div class="col-12">
												<label>ID</label>
												<div class="fs-12 text-muted">{{ api_input?.page_url[0].page_id }}</div>
											</div>
										</div>
									</el-collapse-item>
								</el-collapse>
							</div>
						</div>

						<div v-if="task?.product_type == 'ai'" class="col-6 pr-2 mb-3">
							<label>{{ $t('CategoryCards.category_name.label') }}</label>
							<div class="fs-12 disabled-input">{{ api_input?.category_name ?? '---' }}</div>
						</div>

						<div :class="['mb-3', task?.product_type == 'ai' ? 'col-6 pl-2' : 'col-12']" style="display: grid; align-content: space-between;">
							<label>{{ $t('CategoryCards.content_length.label') }}</label>
							<div class="fs-12 disabled-input">{{ api_input?.content_length }}</div>
						</div>

						<div class="col-12 mb-3">
							<label>{{ $t('taskAssignment.priority.label') }}</label>
							<div class="fs-12 disabled-input text-capitalize">{{ task?.priority }}</div>
						</div>

						<div class="col-12 mb-3">
							<label>{{ $t('taskAssignment.deadline.label') }}</label>
							<div class="fs-12 disabled-input">{{ formatDate(task?.deadline) }}</div>
						</div>
					</div>
				</el-collapse-item>
			</el-collapse>
		</div>	
	</div>
	
	<!-- reject form -->
	<el-dialog title="Why are you rejecting this task? " :visible.sync="rejectFormVisible" class="dialog-md">
		<p class="mb-4">
			Provide feedback on this task so that your team can resolve any issues - the more detail you provide, the easier this will be. 
			You will receive an email when they resubmit this task for your approval.
		</p>
		<ValidationObserver v-slot="{ handleSubmit, valid }">
			<form @submit.prevent="handleSubmit(updateTask('rejected'))">
				<ValidationProvider rules="required">
					<base-textarea :placeholder="'Add your feedback here'" v-model="rejected_note">
					</base-textarea>
				</ValidationProvider>

				<div class="d-flex">
					<base-button class="btn_add_link w-100 mr-1" type="primary" @click="rejectFormVisible = false">
					Cancel
					</base-button>
					<base-button class="btn__generate w-100 ml-1" native-type="submit" type="primary" @click="rejectFormVisible = false" :disabled="!valid">
					Reject with feedback
					</base-button>
				</div>
			</form>
		</ValidationObserver>
	</el-dialog>
	<!-- and reject form -->
	
</card>





</template>

<script>
import { Select, Option, Tag, Collapse, CollapseItem, MessageBox, Dialog, Button, DatePicker } from 'element-ui';
import { formatDate } from '@/util/date';
import { BaseTextarea } from 'src/components/index';

export default {
	name: "TaskAssignment",
	components: {
		[Tag.name]: Tag,
		[Option.name]: Option,
		[Select.name]: Select,
		[Collapse.name]: Collapse,
		[CollapseItem.name]: CollapseItem,
		[Dialog.name]: Dialog,
		[Button.name]: Dialog,
		[DatePicker.name]: DatePicker,
		[MessageBox.name]: MessageBox,
		BaseTextarea
	},
	props: {
		task: {
			type: Object,
			required: true
		},
		order: {
			type: Object
		}
	},
	data() {
		return {
			orderCollapse: [],
			pageUrlCollapse: [],
			brandVoiceCollapse: [],
			orderCollapseTitle: 'View details',
			pageUrlCollapseTitle: 'More Info',
			brandVoiceCollapseTitle: 'More Info',
			form: {
				priority: "",
				deadline: "",
				assignee_id: null,
				status: ""
			},
			loadingUsers: false,
			updating: false,
			users: [],
			statuses:  [
				{ label: 'Waiting', value: 'waiting'},
				{ label: 'On going', value: 'on_going'},
				{ label: 'Ready', value: 'ready', /* disabled: true */ },
				{ label: 'Rejected', value: 'rejected', disabled: true},
				{ label: 'Approved', value: 'approved', disabled: true},
				{ label: 'Completed', value: 'completed', /* disabled: true */ },
			],
			confirmBtnDisabled: false,
			rejectFormVisible: false,
			rejected_note: ''
		}
	},

	computed: {
		user() {
			return this.$store.getters.getUser;
		},
		api_input() {
			return this.order?.json_data?.api_input;
		}
	},

	watch: {
		orderCollapse(item) {
			this.orderCollapseTitle = item[0] == 'order' ? 'Hide details' : 'View details'
		},
		pageUrlCollapse(item) {
			this.pageUrlCollapseTitle = item[0] == 'page-url' ? 'Hide Info' : 'More Info'
		},
		brandVoiceCollapse(item) {
			this.brandVoiceCollapseTitle = item[0] == 'brand-voice' ? 'Hide Info' : 'More Info'
		},
		task() {
			this.form = { ...this.task };
		},
		/* "form.status"(status) {
			if (!['waiting', 'on_going'].includes(status)) {
				this.statuses[0].disabled = true;
				this.statuses[1].disabled = true;
			}
		} */
	},

	methods: {
		formatDate,

		retrieveUsers() {
			this.loadingUsers = true;
			this.$store.dispatch("fetchWorkspaceUsers", this.$store.getters.selectedWorkspace.id).then(() => {
				this.loadingUsers = false;
				this.users = this.$store.getters.workspaceUsers.filter((user) => user.role == 'user');
				//this.users = this.$store.getters.workspaceUsers.filter((user) => this.form.assignees == 'admin' ? ['master', 'intermediate'].includes(user.role) : user.role == 'user');
			});
		},

		async updateTask(status = this.form.status, assignee = this.form.assignee_id) {
			this.updating = true;
			await this.$store.dispatch('updateTask', {
				'order_detail_id': this.$route.params.order_detail_id,
				'priority': this.form.priority, 
				'deadline': this.form.deadline, 
				'status': status,
				'assignee_id': assignee,
				'rejected_note': status == 'rejected' ? this.rejected_note : null,
				'rejected_by': status == 'rejected' && this.user.role != 'user' ? this.user.id : null
			})
			this.updating = false;
		},

		confirmTask() {
			MessageBox.confirm(
				'When you submit, this task will be sent to the reviewer to approve. ', 
				'Confirm edits and submit? ', 
				{
					confirmButtonText: 'Confirm edits and submit',
					cancelButtonText: 'Cancel',
				})
				.then(_ => { this.updateTask('ready') })
				.catch(_ => { });
		},

		approveTask() {
			this.updateTask('approved').then(() => {
				MessageBox.confirm(
				'', 
				'Task approved', 
				{
					cancelButtonText: 'Close',
					showConfirmButton: false
				});
			})
		},

		publishTask() {
			MessageBox.confirm(
				'Do you want to mark the task as completed?', 
				'Mark as completed?', 
				{
					confirmButtonText: 'Mark as completed',
					cancelButtonText: 'Cancel',
				})
				.then(_ => { this.updateTask('completed') })
				.catch(_ => { });
		}

	},
	async created() {
		this.form = { ...this.task };
		this.retrieveUsers();
	},
	beforeDestroy() {
		this.$store.commit('clearModuleTasks');
	}
};
</script>


<style lang="scss">

#taskAssignmentCard {
	.card-body {
		padding: 1rem 1rem .5rem;
	}

	.disabled-input {
		color: white;
		background: #2d2f30;
		border: 1px solid #666;
		border-radius: 6px;
		padding: 11px 14px;
		line-height: 18px;
		min-height: 42px;
	}

	.el-select.text-capitalize {
		.el-input input {
			text-transform: capitalize;
		}
	}

	.el-collapse {
		border: none;
	}
	
	.el-collapse-item__header {
		line-height: inherit;
		color: #007BFF !important;
		font-weight: 400;
		font-size: 12px;
		text-align: center;
    	display: block;
		padding-top: 10px;
		border-bottom: none;
		border-top: 1px solid #666;
		height: auto;
		background: transparent;
	}

	.el-collapse-item__wrap {
		background: transparent;
		border-bottom: none;
	}

	.el-collapse-item__content {
		padding-bottom: 0;
	}

	.el-collapse-item__arrow {
    	margin-left: 6px;
		transform: rotate(90deg);
	}
	.el-collapse-item__arrow.is-active {
		transform: rotate(-90deg);
	}

	.el-collapse-item {
		display: flex;
    	flex-direction: column-reverse;
	
		.further-info {
			.el-collapse-item__header {
				display: block;
				text-align: center;
				border-bottom: none;
				font-size: 12px;
				text-decoration: underline;
			}
			.el-collapse-item__wrap {
				//border: 1px solid #004583;
				border-radius: 5px;
				padding: 0 4px;
				margin-bottom: .75rem;
			}
		}
	}
}

.white-content {

	#taskAssignmentCard {
		.disabled-input {
			color: #004583;
			background: #F2F4F6;
			border: 1px solid #D8D8D8;
		}

		.el-collapse-item__header {
			border-top: 1px solid #ECEEF1;
		}
	}

}
</style>