<template>
  <div class="d-flex justify-content-between version_history">
    <!-- Editor per testo versione -->
    <div class="version_history__content">
      <h1 v-html="order?.title ? order?.title : `Order Id : ${order?.order_id}`"></h1>
      <span v-html="highlightedText"></span>
    </div>
    <!-- Container per tracciamento versioni -->
    <div class="version_history__saved">
      <h1>{{ $t('versionHistory.title') }}</h1>
      <p>{{ $t('versionHistory.browseCompareSelect') }}</p>
      <div>
        <h4>{{ previousOrder.length + 1 }} {{ $t('versionHistory.saved') }}</h4>

        <div class="version_history__saved__list">

          <ul class="dotted-list">
            <li class="dotted-list__item" :class="selectedPreviousOrder.id == order.id ? 'selected' : ''"
              @click="selectOrderVersion(order)">
              Current version
              <p>
                <i class="fa-solid fa-user"></i>
                {{ currentUser ?? order?.user?.name }}
              </p>
            </li>
            <li class="dotted-list__item" :class="selectedPreviousOrder.id == orderItem.id ? 'selected' : ''"
              v-for="orderItem in previousOrder" :key="orderItem.id">
              <div @click="selectOrderVersion(orderItem)">
                {{ formatDate(orderItem.created_at) }}
                <p v-show="orderItem.username">
                  <i class="fa-solid fa-user"></i>
                  {{ orderItem.username }}
                </p>
              </div>
              <div v-show="selectedPreviousOrder.id == orderItem.id">
                <base-button @click="restoreOrder(order)" native-type="submit" type="primary"
                  class="btn-fill btn_submit_form btn_profile">{{ $t('versionHistory.restore') }}
                </base-button>
                <base-button @click="selectOrderVersion('order')" class="btn-fill mt-1 btn_submit_form btn_add_link">
                  {{ $t('workspace.cancel') }}
                </base-button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- <p @click="showVersion(order)">showVersion</p> -->
    </div>
  </div>
</template>

<script>
import HtmlDiff from 'htmldiff-js';
export default {
  name: 'VersionHistoryDialog',
  props: {
    order: Object,
    showVersionValue: Boolean
  },
  components: {
  },
  watch: {
    showVersionValue() {
      if (this.showVersionValue) {
        this.showVersion()
      }
    }
  },
  data() {

    return {
      selectedPreviousOrder: {
        text: '',
        id: '',
      },
      previousOrder: [],
      currentUser: null
    }
  },
  computed: {
    highlightedText() {
      if (this.selectedPreviousOrder.text) {
        return this.computeDifferences(this.order?.content, this.selectedPreviousOrder.text);
      }
      return this.order?.content
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    async showVersion(order) {
      this.$preloader.fadeIn();
      let response;
      response = await this.$store.dispatch('showOrderHistory', this.order.order_id);
      if (response) {
        this.previousOrder = response.data.data
        this.currentUser = response.data.current_user
        this.$preloader.fadeOut()
      } else {
        this.$message({
          showClose: true,
          message: this.$t("versionHistory.errorShowing"),
          type: 'error',
        });
        this.$preloader.fadeOut();

      }
      this.$preloader.fadeOut();
    },

    async restoreOrder(order) {
      this.$preloader.fadeIn();
      let params;
      let response
      params = {
        order_id: this.order.order_id,
        action: 'version_restore',
        order_history_id: this.selectedPreviousOrder.id
      }
      response = await this.$store.dispatch('updateOrder', params);
        this.$message({
          showClose: true,
          message: this.$t("versionHistory.restored"),
          type: 'success',
        });
        const paramsOrder = {
          id: this.$route.params.order_detail_id
        }
        await this.$store.dispatch('fetchOrder', paramsOrder);
        this.showVersion()
        this.selectOrderVersion('order')


      this.$preloader.fadeOut();
    },

    selectOrderVersion(item) {
      if (item == 'order') {
        this.selectedPreviousOrder.text = ''
        this.selectedPreviousOrder.id = ''
        return
      }
      this.selectedPreviousOrder.text = item.text || item.html_content;
      this.selectedPreviousOrder.id = item.id
    },
    computeDifferences(currentOrder, previousOrder) {
      const extractTextContent = (htmlString) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        
        return tempElement.innerHTML || tempElement.innerHTML || '';
      };
      const currentText = extractTextContent(currentOrder);
      const previousText = extractTextContent(previousOrder);
      return HtmlDiff.execute(previousText, currentText);
    },
    
    formatDate(date) {
      const dateTime = new Date(date);
      dateTime.setHours(dateTime.getHours() + 1);

      const formattedDateTime = dateTime.toISOString().replace("T", " ").replace(/\.\d+Z$/, "");

      return formattedDateTime;
    }

  },
  mounted() {
    // this.selectedOrder = this.order?.content
    this.showVersion()
  }

};

</script>