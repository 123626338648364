<template>
  <card class="website_panel">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h4 slot="header" class="title mb-0">{{ $t('workspace.settings.websiteSettings') }}</h4>
        <p slot="header" class="subtitle mb-0">{{ $t('workspace.settings.websiteDesc') }}</p>
      </div>
      <base-button @click="dialogFormVisible = true, updatingWebsite = null, formComponentKey++" native-type="submit"
        type="primary" class="btn-fill ml-3 my-0 py-3 px-3 btn_add_link">
        {{ $t('workspace.settings.addWebsite') }}
      </base-button>
    </div>
    <WebsiteSettingsTable @update="showForm" />

    <!-- Form -->
    <el-dialog :title="updatingWebsite ? $t('workspace.settings.editWebsite') : $t('workspace.settings.addNewWs')"
      :visible.sync="dialogFormVisible" class="dialog-sm">
      <WebsiteCreateForm :updatingWebsite="updatingWebsite" :key="formComponentKey" @hideForm="hideForm" />
    </el-dialog>

  </card>
</template>

<script>
import WebsiteSettingsTable from './WebsiteSettingsTable.vue';
import WebsiteCreateForm from './WebsiteCreateForm.vue';
import { Dialog, Select, Option } from 'element-ui';

export default {
  components: {
    WebsiteSettingsTable,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    WebsiteCreateForm,
  },
  data() {
    return {
      dialogFormVisible: false,
      updatingWebsite: null,
      formComponentKey: 0
    };
  },
  computed: {

  },
  methods: {
    showForm(website) {
      this.formComponentKey++;
      this.updatingWebsite = website;
      this.dialogFormVisible = true;
    },
    hideForm() {
      this.dialogFormVisible = false;
    }
  }
};
</script>