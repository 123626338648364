import axios from "../axios";

export { createTextOrder, createMediaOrder, updateOrderApi };

// TODO spostare le funzioni dentro il modulo orders.js dello store

const createTextOrder = async (bodyParams) => {
  return await axios
    .put("services/orders/create", bodyParams)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

const createMediaOrder = async (bodyParams) => {
  return await axios
    .post("services/media/orders/create", bodyParams)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

const updateOrderApi = async (bodyParams) => {
  return await axios
    .post("services/orders/update", bodyParams)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
