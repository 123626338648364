<template>
	<div>
		<BreadCrumb>
			<!-- Dashboard -->
			<BreadCrumbItem style="display:inline-block">
				<router-link to="/dashboard" class="breadcrumb-link">
					{{ $t('sidebar.dashboard') }}
				</router-link>
			</BreadCrumbItem>

			<!-- Tool's category -->
			<BreadCrumbItem style="display:inline-block">
				<router-link to="/orders" class="breadcrumb-link">
					Documents
				</router-link>
			</BreadCrumbItem>

			<!-- Tool -->
			<BreadCrumbItem style="display:inline-block">
				<span class="breadcrumb-current">{{ `Order #${order ? order?.order_id : ''}` }}</span>
			</BreadCrumbItem>
		</BreadCrumb>

		<el-dialog title="Content review request" :visible.sync="dialogRequestReview">
			<ValidationObserver v-slot="{ handleSubmit, valid }">
				<form @submit.prevent="handleSubmit(humanOrderDelivered('review'))">
					<div>

						<ValidationProvider rules="required">
							<base-textarea
								:label="'Why are you requesting a review? Indicate the reasons for the review'"
								type="text" :placeholder="'Enter a reason'" v-model="form.reject_note">
							</base-textarea>
						</ValidationProvider>
						<SubmitButton :text="'Request a review'" :isDisabled="!valid" />
					</div>
				</form>
			</ValidationObserver>
		</el-dialog>

		<el-dialog title="Reject this article" :visible.sync="dialogReject">
			<ValidationObserver v-slot="{ handleSubmit, valid }">
				<form @submit.prevent="handleSubmit(humanOrderDelivered('reject'))">
					<div>
						<label>You are decided to reject this content. Please tell us your reason for rejecting this
							article</label>
						<ValidationProvider rules="required">
							<base-textarea :label="'Please tell us your reason for rejecting this article'" type="text"
								:placeholder="'Enter a reason'" v-model="form.reject_note">
							</base-textarea>
						</ValidationProvider>
						<SubmitButton :text="'Reject content'" :isDisabled="!valid" />
					</div>
				</form>
			</ValidationObserver>
		</el-dialog>

		<div>
			<div v-if="isOrderDelivered" class="order__button-wrapper">
				<h4 class="mb-0">Waiting on your approval!</h4>
				<label class="mb-0">Your content has been delivered. You can accept or reject the work done.</label>
				<div class="order-detail__creators-button prc-btn justify-content-start pb-0 mb-0">
					<base-button @click="humanOrderDelivered('accept')" native-type="submit" type="primary"
						class="btn-fill ml-3 my-0 py-3 px-3 btn_submit_form btn_save btn_save__add_member">
						Approve
					</base-button>


					<base-button v-if="!order?.rejectet_num" @click="dialogRequestReview = true" native-type="submit"
						type="primary"
						class="btn-fill ml-3 my-0 py-3 px-3 btn_submit_form btn_save btn_save__add_member">
						Require a review
					</base-button>
					<base-button v-else @click="dialogReject = true" native-type="submit" type="primary"
						class="btn-fill ml-3 my-0 py-3 px-3 btn_submit_form btn_save btn_save__add_member">
						Reject order
					</base-button>
				</div>
			</div>

			<div v-if="isAdminStatus" class="order__button-wrapper">
				<h4 class="mb-0">Refund or reschedule order</h4>
				<div class="order-detail__creators-button prc-btn justify-content-start pb-0 mb-0">
					<base-button @click="humanOrderDelivered('refund')" native-type="submit" type="primary"
						class="btn-fill ml-3 my-0 py-3 px-3 btn_submit_form btn_save btn_save__add_member">
						Refund
					</base-button>

					<base-button @click="humanOrderDelivered('reschedule')" native-type="submit" type="primary"
						class="btn-fill ml-3 my-0 py-3 px-3 btn_submit_form btn_save btn_save__add_member">
						Reschedule order
					</base-button>
				</div>
			</div>
		</div>

		<div v-if="loaded" class="row pb-3">
			<div :class="task && isSticky ? 'sticky-card' : ''" class="col-lg-9">
				<card>
					<!-- Card header -->
					<div class="row">
						<div class="col-md-12 d-lg-flex align-items-center justify-content-between">
							<h4 v-if="statusLabel == 'Accepted'" class="card-title">
								<span v-if="!task">
									{{ $t('ToolsResults.result') }}
								</span>
								<span v-else>
									Editor
								</span>
							</h4>
							<div v-if="order?.content" class="d-flex align-items-center">
								<ContentToolbar :showVersionHistory="true"  @saved="unsavedContent = false" @showDialog="isSticky = !$event" />
							</div>
						</div>
						<div v-if="!task" class="col-md-12 mt-4">
							<div class="row">
								<div class="col-md-3">
									<label class="">Order ID</label>
									<p>{{ order?.order_id }}</p>
								</div>
								<div class="col-md-3 ">
									<label class="">Tool</label>
									<p>{{ order?.product }}</p>
								</div>
								<div class="col-md-3">
									<label class="">Created</label>
									<p>{{ createdAt }}</p>
								</div>
								<div class="col-md-3">
									<label class="">Order Status</label>
									<div class="d-flex align-items-center mb-1" style="gap: 10px">
										<div :class="['status', `status__${status}`]"></div>
										<p class="m-0">{{ statusLabel }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="order?.title" class="seo_article mt-3 ">
						<div class="gen-art-left">
							<input class="mb-0" type="text" v-model="order.title">
						</div>
					</div>

					<!-- Card body -->
					<div class="row mt-4">
						<div class="col">
							<!-- Dynamic content component -->
							<component v-if="contentComponentName" :is="contentComponentName"
								@contentChanged="unsavedContent = $event" />
						</div>

					</div>
				</card>
			</div>

			<!-- RIGHT CONTENT -->
			<div class="col-lg-3">
				<TextCounter v-if="contentComponentName == 'TextContent' && order?.json_data?.api_input?.content_length"
					:content="order.content" :max-content-length="order?.json_data?.api_input?.content_length">
				</TextCounter>

				<TaskAssignment v-if="task" :order="order" :task="task">
				</TaskAssignment>

				<UserComments v-show="userCanUseComments">
				</UserComments>
			</div>
		</div>
		<div v-else v-loading="!loaded" class="row mt-lg"></div>

	</div>
</template>

<script>
import BreadCrumb from '@/components/Breadcrumb/Breadcrumb';
import BreadCrumbItem from '@/components/Breadcrumb/BreadcrumbItem';
import AudioContent from './AudioContent';
import ImageContent from './ImageContent';
import TextContent from './TextContent.vue';
import VideoContent from './VideoContent.vue';
import ContentToolbar from "@/pages/Components/ContentToolbar.vue";
import TaskAssignment from './TaskAssignment.vue';
import { formatDate } from '@/util/date.js'
import { getStatusLabel } from "@/services/ordersService";
import { toSnakeCase, capitalizeFirstLetter } from '@/util/strings';
import { Select, Option, Tag, MessageBox } from 'element-ui';
import { Dialog } from 'element-ui';
import { BaseTextarea } from 'src/components/index';
import SubmitButton from 'src/components/Buttons/SubmitButton.vue';
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules.umd";
import UserComments from "./UserComments/UserComments.vue";
import TextCounter from './TextCounter.vue';

extend("required", required);

export default {
	name: "ContentDetails",
	components: {
		BreadCrumb,
		BreadCrumbItem,
		AudioContent,
		ImageContent,
		TextContent,
		BaseTextarea,
		VideoContent,
		ContentToolbar,
		TextCounter,
		TaskAssignment,
		[Tag.name]: Tag,
		[Option.name]: Option,
		[Select.name]: Select,
		[MessageBox.name]: MessageBox,
		[Dialog.name]: Dialog,
		SubmitButton,
		UserComments,
	},
	data() {
		return {
			loaded: false,
			isSticky: false,
			toggleConfirmDialog: false,
			unsavedContent: null,
			dialogRequestReview: false,
			dialogReject: false,
			form: {
				reject_note: "",
			},
		}
	},
	computed: {
		order() {
			return this.$store.getters.getOrder;
		},
		task() {
			return this.$store.getters.getTask;
		},
		createdAt() {
			return this.order?.created_at ? formatDate(this.order.created_at) : '-';
		},
		status() {
			if (this.order?.admin_status == 'admin_deny_reject') {
				return toSnakeCase(getStatusLabel('failed'))
			}
			return this.order ? toSnakeCase(getStatusLabel(this.order.status)) : "";
		},
		statusLabel() {
			if (this.order?.admin_status == 'admin_deny_reject') {
				return capitalizeFirstLetter(getStatusLabel('failed'))
			}
			return this.order ? capitalizeFirstLetter(getStatusLabel(this.order.status)) : "";
		},
		contentComponentName() {
			let category;

			switch (this.order?.category) {
				case "media":
					if (this.endsWithAny(this.order.content, ["jpg", "jpeg", "png", "gif"])) {
						category = "image";
					} else if (this.endsWithAny(this.order.content, ["mp3"])) {
						category = "audio";
					} else if (this.endsWithAny(this.order.content, ["mp4"])) {
						category = "video";
					}
					break;
				case "text":
				default:
					category = "text";
			}

			if (category) {
				return `${capitalizeFirstLetter(category)}Content`;
			}

			return null;
		},
		isOrderDelivered() {
			if (this.order?.status == 'delivered') {
				return true
			}
			return false
		},
		isAdminStatus() {
			return this.order?.admin_status && this.order?.status == 'expired' ? true : false
		},

		userCanUseComments() {
			return this.order?.category == 'text';
			/* if (window.innerWidth <= 1024 || this.order?.category !== 'text') {
				return false
			}
			return true */
		}
	},
	watch: {
		unsavedContent(isChanged) {
			if (this.task && !this.order.status.includes(['waitinaccg_generation', 'waiting_queue', 'failed'])) {
				if (isChanged) {
					window.onbeforeunload = function () { return ''; }
					window.close = function () { return ''; };
				}
				else {
					window.onbeforeunload = function () { return; }
					window.close = function () { return; };
				}
			}
		},
	},
	methods: {
		endsWithAny(inputString, suffixes) {
			return suffixes.some(suffix => inputString.endsWith(suffix));
		},

		async humanOrderDelivered(action) {
			this.$preloader.fadeIn();
			let orderParams = []
			orderParams.order_detail_id = this.order.order_detail_id,
				orderParams.action = action
			if (action == 'review' || action == 'reject') {
				orderParams.reject_note = this.form.reject_note
			}
			console.log(orderParams);
			await this.$store.dispatch('updateOrder', { ...orderParams });
			this.$preloader.fadeOut();
			this.$message({
				showClose: true,
				message: 'Content Updated',
				type: 'success',
			});
			const params = {
				id: this.$route.params.order_detail_id
			}
			await this.$store.dispatch('fetchOrder', params);
			this.form.reject_note = ""
			this.dialogReject = false
			this.dialogRequestReview = false
		},
	},
	async created() {
		this.$store.commit('clearModuleOrders');
		const params = {
			id: this.$route.params.order_detail_id
		}
		await this.$store.dispatch('fetchOrder', params);

		// Media orders need to execute the getMediaOrder call
		if (this.order.category === "media") {
			params.category = this.order.category; // param need by the api call
			await this.$store.dispatch('fetchOrder', params);
		}

		// TODO change with template->has_task_assignment, all'ordine deve essere passato il parametro has task assignment
		// oppure passare il task con l'ordine

		/* if(this.tools.some(t => t.slug === this.order?.product_key && t.has_task_assignment)) {
			await this.$store.dispatch('fetchTask', this.$route.params.order_detail_id);
		} */
		if (['category_cards', 'category_cards_manual'].includes(this.order?.product_key)) {
			await this.$store.dispatch('fetchTask', this.$route.params.order_detail_id);
			this.isSticky = true;
		}

		this.loaded = true;

	},
	beforeDestroy() {
		window.onbeforeunload = function () { return; }
		window.close = function () { return; };
	},
	beforeRouteLeave (to, from , next) {
		if (this.task && !this.order.status.includes(['waiting_generation', 'waiting_queue', 'failed']) && this.unsavedContent) {
			MessageBox.confirm('All changes will be lost if you leave now. Do you want to continue? ', 'Are you sure you want to leave without saving your work?', {
					confirmButtonText: 'Continue Editing',
					cancelButtonText: 'Yes, close',
				})
				.then(_ => { next(false) })
				.catch(_ => { this.unsavedContent = false, next() });
		} else {
			next()
		}
	}
};
</script>