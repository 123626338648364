<template>
  <div class="row">
    <BreadCrumb>
      <!-- Dashboard -->
      <BreadCrumbItem style="display:inline-block">
        <router-link to="/dashboard" class="breadcrumb-link">
          {{ $t('sidebar.dashboard') }}
        </router-link>
      </BreadCrumbItem>

      <!-- Control Panel -->
      <BreadCrumbItem style="display:inline-block">
        <span class="breadcrumb-current">{{ $t('workspace.settings.title') }}</span>
      </BreadCrumbItem>

    </BreadCrumb>

    <div class="col-12">
      <h1>{{ $t('workspace.settings.title') }}</h1>
    </div>
    <div class="col-12">
      <div class="button_switch_panel-container">
        <button v-if="!isPersonal" @click="switchTab = 'member-settings'" class="button_switch_panel"
          :class="switchTab == 'member-settings' ? 'button_switch_panel--selected' : ''">{{
            $t('workspace.memberSettings') }}</button>
        <button @click="switchTab = 'bv-settings'" class="button_switch_panel"
          :class="switchTab == 'bv-settings' || (isPersonal && switchTab != 'website-settings') ? 'button_switch_panel--selected' : ''">{{
            $t('pricing.downgrade_modal.brand_voices') }}</button>
        <button @click="switchTab = 'website-settings'" class="button_switch_panel"
          :class="switchTab == 'website-settings' ? 'button_switch_panel--selected' : ''">{{
            $t('workspace.settings.website') }}</button>
      </div>
    </div>

    <transition name="tab-fade">
      <div v-if="switchTab == 'member-settings' && !isPersonal" class="col-12">
        <WorkspaceMemberSettings />
      </div>
    </transition>
    <transition name="tab-fade">
      <div v-if="switchTab == 'bv-settings' || (isPersonal && switchTab != 'website-settings')" class="col-12">
        <BrandVoice />
      </div>
    </transition>

    <transition name="tab-fade">
      <div v-if="switchTab == 'website-settings'" class="col-12">
        <WebsiteSettings />
      </div>
    </transition>

  </div>
</template>
<script>
import WorkspaceMemberSettings from './WorkspaceMemberSettings/WorkspaceMemberSettings.vue';
import BrandVoice from './BrandVoiceSettings/BrandVoice.vue';
import WebsiteSettings from './WebsiteSettings/WebsiteSettings.vue';
import BreadCrumb from '@/components/Breadcrumb/Breadcrumb';
import BreadCrumbItem from '@/components/Breadcrumb/BreadcrumbItem';

export default {
  components: {
    WorkspaceMemberSettings,
    BreadCrumb,
    BreadCrumbItem,
    BrandVoice,
    WebsiteSettings,
  },
  data() {
    return {
      switchTab: 'member-settings',
    }
  },
  computed: {
    isPersonal() {
      return this.$store.getters.selectedWorkspace.id == null;
    }
  },
};
</script>