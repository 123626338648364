<template>
  <card>
    <div class="d-flex align-items-center justify-content-between brand_voice_panel">
      <div>
        <h4 slot="header" class="title mb-0">{{
          $t('pricing.downgrade_modal.brand_voices') }}</h4>
        <p slot="header" class="subtitle mb-0">{{ $t('workspace.settings.bvDesc') }}</p>
      </div>
    </div>
    <BrandVoiceTable />
    <!-- <BrandVoiceTable @update="showUpdateForm" /> -->

  </card>
</template>
<script>
import BrandVoiceTable from './BrandVoiceTable.vue';
import { Dialog } from 'element-ui';

export default {
  components: {
    BrandVoiceTable,
    [Dialog.name]: Dialog,
  },
  props: {
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    label: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      updatingBrandVoice: null,
      formComponentKey: 0
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  }
};
</script>