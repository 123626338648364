<template>
  <card
    v-loading.target="loading"
    class="content_detail order__comments"
    ref="cardBody"
    :footerClasses="[overflowVisible && !replyToUser ? 'box-shadow' : '']"
  >
    <div
      v-show="!commentsList.length"
      class="order__comments__empty_state my-lg"
    >
      <div class="order__comments__empty_state--img">
        <img src="/img/empty-comments-state.svg" alt="" />
      </div>
      <h4>No Comments yet!</h4>
      <span>Your comments will appear here.</span>
    </div>
    <div class="order__comments__wrapper" ref="orderContainer">
      <div
        v-for="(comment, index) in commentsList"
        :key="index"
        class="order__comments__container"
        :class="
          activeClass === index ? 'order__comments__container--selected' : ''
        "
      >
        <div class="order__comments__parent">
          <div
            class="order__comments__parent"
            @click.prevent="isCommentSelected(index)"
          >
            <div class="comment-author">{{ comment.user_name }}</div>
            <span class="comment-time">{{
              formatDate(comment.created_at)
            }}</span>
            <p
              class="comment-text"
              v-if="editavailable == null || editavailable !== comment.id"
              v-html="formatComment(comment.text)"
            ></p>
          </div>
          <ValidationObserver
            v-if="editavailable == comment.id"
            class=""
            v-slot="{ handleSubmit, valid }"
          >
            <form @submit.prevent="handleSubmit(editComment(comment, 'edit'))">
              <div>
                <ValidationProvider rules="required">
                  <BaseInput
                    @keyup="handleInputChange($event, 'editComment')"
                    id="editComment"
                    class="mb-0"
                    type="text"
                    placeholder="Reply or add people with @"
                    v-model="form.comment"
                  >
                  </BaseInput>
                </ValidationProvider>
                <div
                  v-show="form.editavailable"
                  class="d-flex align-items-center order__comments__parent--button mr-1"
                >
                  <SubmitButton :text="'Edit'" :isDisabled="!valid" />

                  <base-button
                    @click.prevent="
                      (editavailable = null),
                        (replyToUser = false),
                        (form.comment = '')
                    "
                    type="secondary"
                    class="btn-fill mt-0 py-3 px-3 btn_add_link ml-1"
                  >
                    {{ $t("workspace.cancel") }}
                  </base-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
          <BaseDropdownComments
            :comment="comment"
            :user="user"
            @delete="showOrderComments"
            @edit="editComment"
          />
        </div>
        <div
          v-if="!arrowDirection && comment?.children.length > 0"
          v-show="replyToUser === index"
          class="order__comments__children"
        >
          <div class="comment-author">
            {{ comment?.children[0]?.user_name }}
          </div>
          <span class="comment-time">{{
            formatDate(comment?.children[0]?.created_at)
          }}</span>
          <p
            class="comment-text"
            v-if="
              editavailable == null || editavailable !== comment.children[0].id
            "
            v-html="formatComment(comment?.children[0]?.text)"
          ></p>
          <ValidationObserver
            v-if="editavailable == comment.children[0].id"
            class=""
            v-slot="{ handleSubmit, valid }"
          >
            <form
              @submit.prevent="
                handleSubmit(editComment(comment.children[0], 'edit'))
              "
            >
              <div>
                <ValidationProvider rules="required">
                  <base-dropdown
                    style="
                      left: 90px;
                      transform: translateY(65px) !important;
                      z-index: 999999;
                    "
                    v-show="showUserSelect == 'replyComment'"
                    :setShowIsOpen="showUserSelect"
                    tag="div"
                    title-tag="a"
                    class="nav-item"
                    title-classes=""
                    menu-classes="dropdown-navbar dropdown__container_menu"
                  >
                    <li
                      v-for="user in filteredUsers"
                      :key="user.id"
                      class="nav-link dropdown__container"
                      @click="copyUserName(user)"
                    >
                      {{ user.name.trim() != "" ? user.name : user.email }}
                    </li>
                  </base-dropdown>
                  <BaseInput
                    @keyup="handleInputChange($event, 'replyComment')"
                    id="replyComment"
                    class="mb-0"
                    type="text"
                    placeholder="Reply or add people with @"
                    v-model="form.comment"
                  >
                  </BaseInput>
                </ValidationProvider>
                <div
                  v-show="form.editavailable"
                  class="d-flex align-items-center order__comments__parent--button"
                >
                  <SubmitButton
                    :text="'Edit'"
                    class="mr-1"
                    :isDisabled="!valid"
                  />

                  <base-button
                    @click.prevent="
                      (editavailable = null),
                        (replyToUser = false),
                        (form.comment = '')
                    "
                    type="secondary"
                    class="btn-fill mt-0 py-3 px-3 btn_add_link ml-1"
                  >
                    {{ $t("workspace.cancel") }}
                  </base-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
          <BaseDropdownComments
            :comment="comment.children[0]"
            :user="user"
            @delete="showOrderComments"
            @edit="editComment"
          />
        </div>
        <div
          v-else
          v-show="replyToUser === index"
          v-for="(children, idx) in comment.children"
          class="order__comments__children"
          :key="idx"
        >
          <div class="comment-author">{{ children.user_name }}</div>
          <span class="comment-time">{{
            formatDate(children.created_at)
          }}</span>
          <p class="comment-text" v-html="formatComment(children.text)"></p>
          <BaseDropdownComments
            :comment="children"
            :user="user"
            @delete="showOrderComments"
            @edit="editComment"
          />
        </div>
        <div
          class="order__comments__button-container"
          v-show="replyToUser === index && !editavailable"
        >
          <ValidationObserver class="" v-slot="{ handleSubmit, valid }">
            <form
              @submit.prevent="
                handleSubmit(createComments('replyToUser', comment))
              "
            >
              <div>
                <ValidationProvider rules="required">
                  <base-dropdown
                    style="
                      left: 90px;
                      transform: translateY(65px) !important;
                      z-index: 999999;
                    "
                    v-show="showUserSelect == 'replyCommentChild2'"
                    :setShowIsOpen="showUserSelect"
                    tag="div"
                    title-tag="a"
                    class="nav-item"
                    title-classes=""
                    menu-classes="dropdown-navbar dropdown__container_menu"
                  >
                    <li
                      v-for="user in filteredUsers"
                      :key="user.id"
                      class="nav-link dropdown__container"
                      @click="copyUserName(user)"
                    >
                      {{ user.name.trim() != "" ? user.name : user.email }}
                    </li>
                  </base-dropdown>
                  <BaseTextarea
                    @keyup="handleInputChange($event, 'replyCommentChild2')"
                    id="replyCommentChild2"
                    class="mb-0"
                    type="text"
                    placeholder="Reply or add people with @"
                    v-model="form.comment"
                  >
                  </BaseTextarea>
                </ValidationProvider>

                <div
                  v-show="form.comment"
                  class="d-flex align-items-center order__comments__parent--button"
                >
                  <SubmitButton
                    :text="'Reply'"
                    class="mr-1"
                    :isDisabled="!valid"
                  />

                  <base-button
                    @click.prevent="(replyToUser = false), (form.comment = '')"
                    type="secondary"
                    class="btn-fill mt-0 py-3 px-3 btn_add_link ml-1"
                  >
                    {{ $t("workspace.cancel") }}
                  </base-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div
          class="order__comments__view_button"
          v-show="replyToUser === index && comment.children.length > 1"
          @click="arrowDirection = !arrowDirection"
        >
          <p style="color: #007bff !important">
            View {{ arrowDirection ? "less" : "more" }}
            <i
              class="fa-regular"
              :class="arrowDirection ? 'fa-angle-up' : 'fa-angle-down'"
            ></i>
          </p>
        </div>
      </div>
    </div>
    <template slot="footer">
      <ValidationObserver
        v-show="replyToUser === false"
        v-slot="{ handleSubmit, valid }"
      >
        <form @submit.prevent="handleSubmit(createComments('newComment'))">
          <ValidationProvider rules="required">
            <BaseTextarea
              @keyup="handleInputChange($event, 'createComment')"
              id="createComment"
              class="mb-0"
              type="text"
              placeholder="Leave a comment or add people with @"
              v-model="form.comment"
            >
            </BaseTextarea>
            <base-dropdown
              style="left: inherit; transform: inherit; z-index: 999999"
              v-show="showUserSelect == 'createComment'"
              :setShowIsOpen="showUserSelect"
              tag="div"
              title-tag="a"
              class="nav-item"
              title-classes=""
              menu-classes="dropdown-navbar dropdown__container_menu select-tag-dropdown"
            >
              <li
                v-for="user in filteredUsers"
                :key="user.id"
                class="nav-link dropdown__container"
                @click="copyUserName(user)"
              >
                {{ user.name.trim() != "" ? user.name : user.email }}
              </li>
            </base-dropdown>
          </ValidationProvider>

          <SubmitButton
            v-show="form.comment"
            :text="'Leave a comment'"
            :icon="'fa-solid fa-message-medical mr-1'"
            :isDisabled="!valid"
          />

          <base-button
            v-show="form.comment"
            @click.prevent="form.comment = ''"
            type="secondary"
            class="btn-fill mt-2 py-3 px-3 mb-0 w-100 btn_add_link"
          >
            {{ $t("workspace.cancel") }}
          </base-button>
        </form>
      </ValidationObserver>
    </template>
  </card>
</template>
<script>
import { Dialog } from "element-ui";
import { BaseTextarea, BaseInput } from "src/components/index";
import SubmitButton from "src/components/Buttons/SubmitButton.vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules.umd";
import { copyToClipboard } from "@/util/utils";
import BaseDropdownComments from "./BaseDropdownComments.vue";

extend("required", required);

export default {
  name: "UserComments",
  components: {
    BaseTextarea,
    [Dialog.name]: Dialog,
    SubmitButton,
    BaseInput,
    BaseDropdownComments,
  },
  data() {
    return {
      form: {
        comment: "",
        reply: "",
        editComment: "",
      },
      replyToUser: false,
      activeClass: false,
      arrowDirection: false,
      commentsList: [],
      editavailable: null,
      getUrl: null,
      showUserSelect: "",
      users: [],
      selectedUser: [], // Track the selected user
      inputBuffer: "", // Buffer to store the text after "@"
      overflowVisible: false,
      loading: false,
    };
  },
  computed: {
    order() {
      return this.$store.getters.getOrder;
    },

    // Filtered user list based on input buffer
    filteredUsers() {
      return this.users.filter((user) =>
        user.name.trim() != ""
          ? user.name.toLowerCase().startsWith(this.inputBuffer.toLowerCase())
          : user.email.toLowerCase().startsWith(this.inputBuffer.toLowerCase())
      );
    },

    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    commentsList() {
      this.$nextTick(() => {
        this.overflowVisible =
          this.$refs.orderContainer.scrollHeight >
          this.$refs.cardBody.$el.scrollHeight;
      });
    },
  },
  methods: {
    copyToClipboard,

    async showOrderComments() {
      this.loading = true;
      let params = {
        order_detail_id: this.$route.params.order_detail_id,
      };
      const response = await this.$store.dispatch("showOrderComments", params);
      this.commentsList = response.data.data.comments;
      this.loading = false;
      this.showWorkspaceUsersListForComments();
    },

    async createComments(commentType, comment) {
      this.loading = true;
      let params = {
        order_detail_id: this.$route.params.order_detail_id,
      };
      if (commentType == "newComment") {
        params.text = this.form.comment;
      }
      if (commentType == "replyToUser") {
        (params.text = this.form.comment),
          (params.parent_comment_id = comment.id);
      }
      if (this.selectedUser) {
        params.answer_to_users = this.selectedUser;
      }
      await this.$store.dispatch("createComments", { ...params });
      this.$message({
        showClose: true,
        message: "Comment created",
        type: "success",
      });
      this.form.comment = "";
      this.selectedUser = [];
      this.replyToUser = false;
      this.loading = false;
      this.showOrderComments();
    },

    async deleteComments(id) {
      this.loading = true;
      let params = {
        order_detail_id: this.$route.params.order_detail_id,
        comment_id: id,
      };
      await this.$store.dispatch("deleteComments", params);
      this.$message({
        showClose: true,
        message: "Comment deleted",
        type: "success",
      });
      this.loading = false;
      this.showOrderComments();
    },

    isCommentSelected(index) {
      this.arrowDirection = false;
      this.replyToUser = false;
      this.replyToUser = index;
      this.activeClass = index;
      this.form.comment = "";

      setTimeout(() => {
        this.activeClass = false;
      }, 5000);
    },

    formatDate(value) {
      const dateString = value;
      const date = new Date(dateString);

      const formattedDay = date.getDate().toString().padStart(2, "0");
      const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
      const formattedHours = date.getHours().toString().padStart(2, "0");
      const formattedMinutes = date.getMinutes().toString().padStart(2, "0");
      const formattedDate = `${formattedDay}/${formattedMonth}/${date.getFullYear()} - ${formattedHours}:${formattedMinutes}`;
      return formattedDate;
    },
    async editComment(comment, action) {
      if (action == "edit") {
        this.loading = true;
        let params = {
          order_detail_id: this.$route.params.order_detail_id,
          comment_id: comment.id,
          text: this.form.comment,
        };
        await this.$store.dispatch("editComments", params);
        this.$message({
          showClose: true,
          message: "Comment edited",
          type: "success",
        });
        this.loading = false;
        this.showOrderComments();
        this.editavailable = null;
        this.form.comment = "";
        return;
      }
      this.editavailable = comment.id;
      this.form.comment = comment.text;
    },

    async showWorkspaceUsersListForComments() {
      this.loading = true;
      let params = {
        order_detail_id: this.$route.params.order_detail_id,
      };
      const response = await this.$store.dispatch(
        "showWorkspaceUsersListForComments",
        params
      );
      this.users = response.data.data.users.filter(
        (user) => user.id !== this.user.id
      );
      this.loading = false;
    },

    // Method to handle input change
    handleInputChange(event, id) {
      const inputValue = event.target.value;
      // Check if "@" is present in the input value and it is the last character
      if (inputValue.endsWith("@")) {
        this.showUserSelect = id;
        this.inputBuffer = ""; // Reset input buffer
      } else if (inputValue.endsWith("@ ")) {
        // Check for "@ " to close dropdown
        this.showUserSelect = "";
        this.inputBuffer = ""; // Reset input buffer
      } else if (inputValue.includes("@")) {
        // Check for "@" in the middle of the text
        this.showUserSelect = id;
        // Update input buffer to store text after "@"
        this.inputBuffer = inputValue.substring(
          inputValue.lastIndexOf("@") + 1
        );
      } else {
        this.showUserSelect = "";
        this.inputBuffer = ""; // Reset input buffer
      }
    },

    copyUserName(user) {
      const userName = user.name.trim() != "" ? user.name : user.email;
      const atIndex = this.form.comment.lastIndexOf("@"); // Find the position of "@"
      if (atIndex !== -1) {
        // Update the v-model with the selected user name at "@" position
        this.form.comment =
          this.form.comment.slice(0, atIndex) + "@" + userName + " ";
      }
      this.selectedUser.push(user.id);
    },

    formatComment(text) {
      // Regular expression to match '@username' pattern
      const regex = /@(\S+(?:\s+\S+)*)/g;
      return text.replace(regex, (match, username) => {
        // Check if the username exists in the users array
        const user = this.users.find((user) => user.name === username);
        if (user) {
          // If the username exists, apply different style or class
          return `<span style="color: #007BFF;">${match}</span>`;
        }
        return match; // If username doesn't exist, return unchanged
      });
    },
  },
  mounted() {
    this.getUrl = window.location.href;
    this.showOrderComments();
  },
};
</script>
