<template>
  <div>
    <h3 class="images-title">Images</h3>
      <div class="select-images-gallery">
        <div v-for="image in images" :key="image.filename"
        class="blog_post_paragraphs_order__img">
          <img :src="image.shareable_url" :alt="image.description">
        </div>
      </div>
  </div>
</template>
<script>
export default {
  props:{
    images: {
      type: Array,
    }
  },
}
</script>
<style scoped>
.images-title {
    margin-bottom: 0;
}

.select-images-gallery {
    display: flex;
    gap: 20px;
    margin: 24px 0 40px;
}
.select-images-gallery div{
  cursor: default;
  pointer-events: none;
}
.select-images-gallery img {
  cursor: default;
  pointer-events: none;
}
.confirm-button-row {
    float: right;
}
#images {
    padding: 2rem 0 5rem;
}

.blog_post_paragraphs_order__img--fix{
    min-height: auto;
    width: auto;
}

.blog_post_paragraphs_order__img--selected--icon--fix{
    right: 50%;
    transform: translateX(+50%);
}
</style>
