<template>
	<div>
		<div class="seo-tag-row">
			<div class="seo-tag-l">
				{{ $t("SeoMetatagsAnalyzer.title") }}
			</div>
			<div class="seo-tag-r">
				{{
					order.metaTitle
						? order.metaTitle
						: this.$t('SeoMetatagsAnalyzer.not_found')
				}}
			</div>
		</div>
		<div class="seo-tag-row">
			<div class="seo-tag-l">
				{{ $t("SeoMetatagsAnalyzer.description") }}
			</div>
			<div class="seo-tag-r">
				{{
					order.metaDescription
						? order.metaDescription
						: this.$t('SeoMetatagsAnalyzer.not_found')
				}}
			</div>
		</div>
		<div class="seo-tag-row">
			<div class="seo-tag-l">
				{{ $t("SeoMetatagsAnalyzer.keyword") }}
			</div>
			<div class="seo-tag-r">
				{{
					order.metaKeyword
						? order.metaKeyword
						: this.$t('SeoMetatagsAnalyzer.not_found')
				}}
			</div>
		</div>
		<div class="seo-tag-row">
			<div class="seo-tag-l">
				{{ $t("SeoMetatagsAnalyzer.viewport") }}
			</div>
			<div class="seo-tag-r">
				{{
					order.metaViewport
						? order.metaViewport
						: this.$t('SeoMetatagsAnalyzer.not_found')
				}}
			</div>
		</div>
		<div class="seo-tag-row">
			<div class="seo-tag-l">
				{{ $t("SeoMetatagsAnalyzer.robots") }}
			</div>
			<div class="seo-tag-r">
				{{
					order.metaRobots
						? order.metaRobots
						: this.$t('SeoMetatagsAnalyzer.not_found')
				}}
			</div>
		</div>
		<div class="seo-tag-row">
			<div class="seo-tag-l">
				{{ $t("SeoMetatagsAnalyzer.author") }}
			</div>
			<div class="seo-tag-r">
				{{
					order.metaAuthor
						? order.metaAuthor
						: this.$t('SeoMetatagsAnalyzer.not_found')
				}}
			</div>
		</div>
	</div>

</template>

<script>

export default {
	computed: {
		order() {
			return this.$store.getters.getOrder;
		}
	}
};
</script>
