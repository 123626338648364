<template>
  <div>
    <WorkspaceCreate @changeStep="changeStep" v-show="step == 1" />
    <WorkspaceCreateAddUser :workSpaceName="this.workSpaceName" @changeStep="changeStep" v-show="step == 2" />
  </div>
</template>

<script>
import WorkspaceCreate from './WorkspaceCreate';
import WorkspaceCreateAddUser from './WorkspaceCreateAddUser';

export default {
  components: {
    WorkspaceCreate,
    WorkspaceCreateAddUser
  },
  data() {
    return {
      loaded: false,
      step: 1,
      workSpaceName: '',
      workSpacePesonal: {
        'workspace': {
          'id': null,
          'name': 'Personal'
        }
      }
    };
  },
  methods: {
    changeStep(step, name = '') {
      this.step = step
      this.workSpaceName = name
    },
  },
  created() {
    setTimeout(() => {
      let docClasses = document.body.classList;
      docClasses.add('white-content');
    }, 100);
  },
  beforeMount() {
    if (this.$route.query.step == 1) {
      this.step = this.$route.query.step;
    }
  }
};
</script>
