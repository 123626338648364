<template>
    <div :class="['container', contentClass]">
        <div class="background-img"></div>
        <div class="title">{{ firstLine }}</div>
        <div class="subtitle">{{ secondLine }}</div>
    </div>
</template>

<script>
export default {
    props: {

        contentClass: {
            type: String,
            default: "empty-content",
            required: false
        },
        firstLine: {
            type: String
        },
        secondLine: {
            type: String
        },
    },
};
</script>

<style lang="scss">
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .background-img {
        aspect-ratio: 1;
        width: 200px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.empty-content {
        .background-img {
            background-image: url('/img/empty-task-dark.png')
        }
    }

    &.failed-content {
        .background-img {
            background-image: url('/img/failed-order-dark.png')
        }
    }

    .title {
        font-size: 18px;
        font-weight: 700;
        margin-top: 1.5rem;
        color: white !important;
    }

    .subtitle {
        font-size: 16px;
        color: #999999 !important;
    }
}

.white-content {
    .container {

        &.empty-content {
            .background-img {
                background-image: url('/img/empty-task.png');
            }
        }

        &.failed-content {
            .background-img {
                background-image: url('/img/failed-order.png')
            }
        }

        .title {
            color: #004583 !important;
        }
    }
}
</style>
