<template>
	<div class="orderResult">
		<div class="card bg-secondary rounded overflow-hidden d-flex justify-content-center align-items-center"
			style="width: 300px;">
			<audio controls>
				<source :src="order?.content" type="audio/mpeg" />
			</audio>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		order() {
			return this.$store.getters.getOrder;
		}
	}
};
</script>