export default [
    [
        {header: [false, 1, 2, 3, 4, 5, 6]},
    ],
    ["bold", "italic", "underline", "strike"],
    [
        {align: ""},
        {align: "center"},
        {align: "right"},
        {align: "justify"}
    ],
    ["blockquote", "code-block"],
    [
        {list: "ordered"},
        {list: "bullet"},
    ],
    [
        {indent: "-1"},
        {indent: "+1"}
    ],
    [
        {color: []},
        {background: []}
    ],
    ["link", "image", "video"],
    ["clean"],
];