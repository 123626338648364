<template>
<div class="row mt-3 mb-5">
  <div class="col-12">
    <div class="table-responsive table-text-to-image">

      <el-table :data="workspaceMembers" v-loading="loading" style="width: 100%">

        <!-- Email -->
        <el-table-column min-width="200" prop="User" :label="$t('seats.roles.user')" sortable>
          <template #default="scope">
            <label>{{ scope.row.user.email }}</label>
          </template>
        </el-table-column>

        <!-- Role -->
        <el-table-column min-width="250" prop="Access Level" :label="$t('seats.accessLevel')" align="center" sortable>
          <template #default="scope">
              {{ $t('seats.roles.'+ scope.row.role) }}
          </template>
        </el-table-column>

        <!-- Status -->
        <el-table-column min-width="250" prop="Status" :label="$t('seats.status')" align="center" sortable>
          <template #default="scope">
            <label class="pill mb-0 text-capitalize" :class="`pill--${scope.row.user.status}`">
              {{ scope.row.user.status }}
            </label>
          </template>
        </el-table-column>

        <!-- Created At -->
        <el-table-column min-width="200" prop="Created on" :label="$t('resultsTable.createdOn')" align="center" sortable>
          <template #default="scope">
            {{ formatDate(scope.row.created_at) }}
          </template>
        </el-table-column>

        <!-- Actions -->
        <el-table-column :min-width="135" align="center" :label="$t('resultsTable.actions')">
          <template #default="scope">
            <!-- <el-tooltip  content="Remove Member"  :open-delay="300"
              placement="top"> -->
              <el-popconfirm
                v-if="scope.row.role !== 'master'"
                confirm-button-text='Confirm'
                cancel-button-text='Cancel'
                icon=""
                icon-color="red"
                title="Do you want remove this user from workspace?"
                @confirm="removeUserFromWorkspace(scope.row)"
              >
                <base-button slot="reference" icon size="sm" class="btn-simple">
                  <div>
                    <i class="fa-solid fa-trash-xmark"></i>
                  </div>
                </base-button>
              </el-popconfirm>
            <!-- </el-tooltip> -->
          </template>
        </el-table-column>

      </el-table>

    </div>
  </div>
</div>

</template>
<script>
import swal from 'sweetalert2';
import { Table, TableColumn, Select, Option, Tooltip, Popconfirm, Button, Popover } from 'element-ui';
import { formatDate } from '@/util/date';
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Popconfirm.name]: Popconfirm,
    [Button.name]: Button, // used in popconfirm
    [Popover.name]: Popover
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    userRole() {
      return this.$store.getters.getUserRole;
    },
    workspaceMembers() {
      return this.$store.getters.workspaceUsers;
    },
    workspaceId() {
      return this.$route.params?.id ?? this.$store.getters.selectedWorkspace.id;
    }
  },
  methods: {
    formatDate,

    removeUserFromWorkspace(user) {
      this.loading = true;
      this.$store.dispatch('removeUserFromWorkspace', {
        'id': this.workspaceId,
        'users': [user]
      })
      .then(() => {
          this.$store.dispatch("fetchWorkspaceUsers", this.workspaceId).then(() => this.loading = false);
          this.$store.dispatch("fetchExcludedWorkspaceUsers", this.workspaceId);
      })
    }
  },
  mounted() {
    this.$store.dispatch("fetchWorkspaceUsers", this.workspaceId).then(() => this.loading = false);
  }
};
</script>

<style scoped lang="scss">
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}
</style>

