<template>
     <!-- Menu button -->
    <base-dropdown :class="customClass" v-show="comment.user_id == user.id" tag="div" title-tag="a" class="dropdown-comment nav-item" title-classes="" menu-classes="dropdown-navbar dropdown__container_menu">
        <template slot="title">
            <base-button icon size="sm" class="btn-simple dropdown-btn">
                <div>
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                </div>
            </base-button>
        </template>

        <!-- Edit button -->
        <li @click="editComment(comment)" class="nav-link dropdown__container">
            Edit
        </li>

        <!-- Delete button -->
        <li @click="deleteComments(comment.id)" style="white-space: nowrap;" class="nav-link dropdown__container">
            Delete
        </li>

        <!-- Link comment button -->
        <li @click.prevent="copylink()" class="nav-link dropdown__container">
            Get link to the comment
        </li>
    </base-dropdown>
</template>
<script>
import { copyToClipboard } from "@/util/utils";

export default {
    name: "BaseDropdownComments",
    components: {
    },
    props: {
        comment: {},
        user: {
            type: Object
        },
        customClass: {
            type: String,
        }
    },
    data() {
        return {

        }
    },
    computed: {
    },
    methods: {
        copyToClipboard,

        async deleteComments(id) {
            this.$preloader.fadeIn()
            let params = {
                order_detail_id : this.$route.params.order_detail_id,
                comment_id      : id
            }
            await this.$store.dispatch('deleteComments',params);
            this.$message({
				showClose: true,
				message: 'Comment deleted',
				type: 'success',
			});
            this.$preloader.fadeOut()
            this.$emit('delete');
        },

        async editComment(comment,action) {
            this.$emit('edit',comment,action);
        },

        copylink() {
            this.copyToClipboard(this.getUrl)
            this.$message({
                showClose: true,
                message: 'Url copied to clipboard',
                type: 'success',
            });
        },

    },
    mounted() {
        this.getUrl = window.location.href
    },
};

</script>

<style lang="scss">

.dropdown-comment {
    .dropdown-menu {
        width: max-content;
        left: -170px !important;
        box-shadow: 0px 1px 20px 0px #0000001A !important;
    }

    .dropdown-btn {
        height: auto !important;
        min-width: auto !important;
        width: auto !important;

        &:hover {
            box-shadow: none !important;
        }

        i {
            font-size: 16px;
        }
    }

    .nav-link {
        padding: .25rem 1rem !important;
        color: #004583;
        font-size: 12px;
    }
}

</style>