<template>
  <div>
    <h4 v-show="!order?.json_data?.saved_images" class="font-weight-bold images-title">Images</h4>
    <div class="blog_post_paragraphs_order__container">
      <div class="blog_post_paragraphs_order__img_container mb-5"
        :class="order?.status == 'waiting_blogpost_images' ? 'blog_post_paragraphs_order__img_container--empty' : ''">
        <div class="blog_post_paragraphs_order__empty_state" v-show="order?.status == 'waiting_blogpost_images'">
          <img src="/img/empty-state-order.svg" alt="">
          <div>
            <h3>Sorry, we can’t generate your images right now</h3>
            <p>We will send you an email when your images are ready - you’ll find them in the Documents folder
            </p>
          </div>
        </div>
        <div v-show="order?.status == 'waiting_blogpost_article'"
          v-for="(image, index) in order?.json_data?.api_output?.images" :key="image.filename"
          class="blog_post_paragraphs_order__img"
          :class="isImgSelected(index) ? 'blog_post_paragraphs_order__img--selected' : ''">
          <img @click="selectImage(index)" :src="image.shareable_url" :alt="image.description">
          <div v-show="isImgSelected(index)" class="blog_post_paragraphs_order__img--selected--icon">
            <i class="fa-solid fa-check"></i>
          </div>
        </div>
        <!--
                    <div v-show="order?.json_data?.saved_images" v-for="(image, index) in order?.json_data?.saved_images"
                    :key="index" class="blog_post_paragraphs_order__img">
                    <img :src="image.shareable_url" :alt="image.description">
                    </div>
                -->
      </div>
      <div v-show="!order?.json_data?.saved_images" class="confirm-button-row">
        <base-button @click="submitFinalizeArticle">{{ $t('BlogPostParagraphs.finalize') }}</base-button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    order: Object
  },
  data() {
    return {
      selectedImagesIndexList: []

    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {

    selectImage(index) {
      if (this.isImgSelected(index)) {
        const selectedIndex = this.selectedImagesIndexList.indexOf(index);
        this.selectedImagesIndexList.splice(selectedIndex, 1);
      } else {
        this.selectedImagesIndexList.push(index);
      }

      console.log(this.selectedImagesIndexList);
    },
    isImgSelected(index) {
      return this.selectedImagesIndexList.includes(index);
    },
    async submitFinalizeArticle() {
      const imagesArray = [];
      this.order?.json_data?.api_output?.images.forEach((image, index) => {
        if (this.isImgSelected(index)) {
          imagesArray.push(image);
        }
      });

      const form = {
        user_id: this.user.id,
        product_key: "blog_post_paragraphs",
        product_type: "submit_entire_article",
        order_det_id: this.order.order_detail_id,
        selected_images: imagesArray,
        fast_api: true
      };
      const response = await this.$axios.post("services/orders/create", form);

      const params = {
        id: this.order.order_detail_id
      }
      await this.$store.dispatch('fetchOrder', params);
      this.$store.commit('setSavedImages', imagesArray);

    }

  }

};
</script>

<style scoped>
.confirm-button-row {
  float: right;
}
</style>
